import getClientSideQueryClient from './queryClient'
import { bankDataQueryFn, useBankData } from './queries/bankData'
import { useUpdateContactMutation } from './mutations/updateContact'
import { useCreateContactMutation } from './mutations/createContact'
import { useDeleteContactMutation } from './mutations/deleteContact'
import { useContactDeleteMutation } from './mutations/contactDelete'
import { useContactCreateMutation } from './mutations/contactCreate'
import { useContactUpdateMutation } from './mutations/contactUpdate'
import { useBankingDetailsMutation } from './mutations/updateBankingDetails'
import { useUpdateTaxInformationMutation } from './mutations/updateTaxInformation'

export {
  getClientSideQueryClient,
  bankDataQueryFn,
  useBankData,
  useUpdateContactMutation,
  useCreateContactMutation,
  useDeleteContactMutation,
  useContactCreateMutation,
  useContactDeleteMutation,
  useBankingDetailsMutation,
  useContactUpdateMutation,
  useUpdateTaxInformationMutation,
}
