import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { IUpdateBankingDetails } from 'models/customer-data-service/paymentDetails'
import { useSession } from 'next-auth/react'
import { customerBankQueryKey } from 'reactQuery/queryKeys'
import customToast from 'utils/customToast'
import { HttpMethod } from 'utils/types'


/**
 * Client side mutation to update banking details.
 *
 * @returns {Promise<Response>} The server response.
 */
const updateBankingDetailsMutationFn = async (
  params: IUpdateBankingDetails
  ): Promise<Response> => {
  return await fetch('api/contract/updateBankingDetails', {
    method: HttpMethod.PATCH,
    body: JSON.stringify(params),
  })
}

/** Custom model for the mutation variables. */
interface IMutationVars {
  data: IUpdateBankingDetails
  successMessage: string
  errorMessage: string
  /** The additional message to warn the user to change the bank data with the grid operator as well*/
  notifyGridOperatorMessage: string
}

/**
 * Mutation to update banking details
 *
 * @returns {UseMutationResult<IUpdateBankingDetails>} The mutation result.
 */
const useBankingDetailsMutation = (): UseMutationResult<
IUpdateBankingDetails | undefined,
  void,
  IMutationVars
> => {
  const { data: session } = useSession()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (vars) => {
      try {
        const response = await updateBankingDetailsMutationFn(vars.data)

        if (response.status !== 200) {
          throw new Error('could not update banking details')
        }
        const responseJson = await response.json()
        customToast.success(vars.successMessage)
        customToast.warnBanner(vars.notifyGridOperatorMessage)
        return responseJson as IUpdateBankingDetails
      } catch {
        customToast.error(vars.errorMessage)
      }
    },
    
    onSettled: () => {
      if (session?.user?.id) {
        queryClient.invalidateQueries({ queryKey: customerBankQueryKey(session.user.id)}
         )
      }
    }
  })
}

export { useBankingDetailsMutation }
